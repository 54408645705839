<template>
  <div>
    <div class="title">
      服务周期即将到期
    </div>
    <search-agent-service-cycle-unexpired-simCard
      @submit="submitSearch"
      :agent-id="agentId"
    />

    <div class="iotplt-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      row-key="iccid"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import SearchAgentServiceCycleUnexpiredSimCard from '@/views/sim_cards/top_agent_view_agency/service_cycle_manage/service_cycle_unexpired/Search'
import Pagination from '@/components/Pagination'
import { findAgentServiceCycleUnexpiredSimCardsByCustomer, exportAgentServiceCycleUnexpiredSimCardsByCustomer } from '@/api/service_cycle_manage'
import { exportExcel } from '@/api/excel'

export default {
  name: 'AgentServiceCycleUnexpiredSimCard',
  components: {
    SearchAgentServiceCycleUnexpiredSimCard,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '生命周期',
          dataIndex: 'life_cycle'
        },
        {
          title: '联网状态',
          dataIndex: 'net_status'
        },
        {
          title: '服务周期起始时间',
          dataIndex: 'activated_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'activated_at' ? this.sort.sort_order : false
        },
        {
          title: '服务周期结束时间',
          dataIndex: 'service_end_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'service_end_time' ? this.sort.sort_order : false
        },
        {
          title: '预计销卡时间',
          dataIndex: 'expect_cancel_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'expect_cancel_time' ? this.sort.sort_order : false
        },
        {
          title: '客户套餐',
          dataIndex: 'product_name'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          title: '套餐价格(元)',
          dataIndex: 'product_price'
        }
      ]
    },
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      const data = {
        agent_id: this.agentId,
        ...this.query,
        ...this.sort
      }
      findAgentServiceCycleUnexpiredSimCardsByCustomer(data).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    exportData() {
      exportAgentServiceCycleUnexpiredSimCardsByCustomer(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  margin: 20px 0 20px 0;
}
</style>
